@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.zoom-on-hover {
  transition: 0.5s all ease-in-out;
}

.zoom-on-hover:hover {
  transform: scale(1.1); /* Adjust the scale factor as needed */
}
body {
  min-height: 100%;
}

.contact-text {
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-color: black;
  -webkit-text-stroke-width: 2px;
}
